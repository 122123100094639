import {
	DateCell,
	DefaultText,
	GradeCell,
	HeaderCell
} from '@/app/common/components/molecules/cell-builder';
import { formatPrice } from '@/app/utils/currency';
import { tlsx } from '@/app/utils/tw-merge';
import { isDefined } from '@partly/js-ex';
import { draft_order, listing } from '@/sdk/reflect/reflect';
import { Checkbox } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { match } from '@/types/match';
import { OrderRequestItemStatusBadge } from './components/order-request-item-status-badge';

export type OrderRequestTableItem = {
	id: string;
	quantity: number;
	grade: listing.ListingGrade | null;
	status: draft_order.DraftOrderItemStatus | null;
	order_separately: boolean;
	arrival_at: number | null;
	price: number | null;
	name: string;
};

export const draftOrderColumnBuilder = () => {
	const columnHelper = createColumnHelper<OrderRequestTableItem>();

	const select = (hasHeader = true) =>
		columnHelper.display({
			id: 'select',
			header: hasHeader
				? ({ table }) => (
						<Checkbox
							aria-label="Check all rows"
							className="inline-block"
							size="xs"
							{...{
								checked: table.getIsAllRowsSelected(),
								indeterminate: table.getIsSomeRowsSelected(),
								onChange: table.getToggleAllRowsSelectedHandler()
							}}
						/>
					)
				: undefined,
			cell: ({ row }) => (
				<Checkbox
					aria-label="Check row"
					className="inline-block"
					size="xs"
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler()
					}}
				/>
			)
		});

	const quantity = () =>
		columnHelper.accessor('quantity', {
			id: 'quantity',
			header: () => <HeaderCell>Qty.</HeaderCell>,
			cell: info => <DefaultText>{info.getValue()}</DefaultText>
		});

	const name = () =>
		columnHelper.accessor('name', {
			id: 'name',
			header: () => <HeaderCell>Items</HeaderCell>,
			cell: info => <DefaultText className="min-w-[200px]">{info.getValue()}</DefaultText>
		});

	const grade = () =>
		columnHelper.accessor('grade', {
			id: 'grade',
			header: () => <HeaderCell>Grade</HeaderCell>,
			cell: info => <GradeCell value={info.getValue()} />
		});

	const arrivalAt = (header = 'Delivery est.') =>
		columnHelper.accessor('arrival_at', {
			id: 'arrival_at',
			header: () => <HeaderCell>{header}</HeaderCell>,
			cell: info => (
				<DateCell value={info.getValue()} orderSeparately={info.row.original.order_separately} />
			)
		});

	const price = () =>
		columnHelper.accessor('price', {
			id: 'price',
			header: () => <HeaderCell className="text-right">Price</HeaderCell>,
			cell: info => {
				const price = info.getValue();
				if (!isDefined(price)) {
					return <DefaultText className="text-right">--</DefaultText>;
				}

				const { status } = info.row.original;
				const isRejected = status && typeof status === 'object' && 'Rejected' in status;

				return (
					<DefaultText className={tlsx('text-right', { 'line-through': isRejected })}>
						{formatPrice(price)}
					</DefaultText>
				);
			}
		});

	const status = () =>
		columnHelper.accessor('status', {
			id: 'status',
			header: () => <HeaderCell>Status</HeaderCell>,
			cell: info => {
				const status = info.getValue();
				if (!status) {
					return <DefaultText>--</DefaultText>;
				}

				const reason = match<draft_order.DraftOrderItemStatus, string | null>(status, {
					Rejected: ({ details }) => details,
					Approved: ({ details }) => details,
					Pending: () => null
				});

				return (
					<>
						<OrderRequestItemStatusBadge status={status} size="small" />
						{reason && <div className="text-xs text-gray-900 mt-1">{reason}</div>}
					</>
				);
			}
		});

	return {
		select,
		quantity,
		name,
		grade,
		arrivalAt,
		status,
		price
	};
};

import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { jobsQueries } from '@/sdk/react';
import { useMemo } from 'react';
import { uniq } from 'lodash-es';
import { OrderRequestModel } from '../../models';

type PageParams = {
	jobId: string;
};

export const useOrderImageSync = (model: OrderRequestModel) => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Job id is required for order images');
	}

	const client = useQueryClient();
	const { mutateAsync, isPending: isSyncing } = useMutation({
		...bmsMutations.syncJobImages,
		onSuccess: () => {
			client.invalidateQueries(jobsQueries.get({ jobId }));
		}
	});

	const { data: jobData } = useSuspenseQuery({
		...jobsQueries.get({ jobId }),
		refetchOnMount: false
	});

	const images = useMemo(() => {
		return uniq([
			...model.images.map(image => image.original),
			...jobData.job.images.map(image => image.original)
		]);
	}, [model.images, jobData.job.images]);

	const sync = () => mutateAsync({ jobId });
	const canSync = jobData.job.bmsIntegrated;

	return {
		images,
		isSyncing,
		canSync,
		sync
	};
};

import { getGapcSuidType, getUvdbSuidTypes } from '@partly/frontend-utils';
import { isDefined } from '@partly/js-ex';
import { GapcPartRaw, PartSelectionContextsRaw, PartSlotRaw } from '../../generated';
import {
	BaseGapcProperties,
	GapcAssemblyPart,
	GapcBase,
	GapcBrand,
	GapcCategory,
	GapcDiagram,
	GapcPart,
	GapcPartType,
	GapcProperty,
	NormalizedGapcAssemblyPart,
	PartSelectionContexts,
	PartSlot
} from '../types';
import { recursiveToCamel } from '../utils/casing';
import { Context } from '../utils/context';
import { getId } from '../utils/parsing';
import { encodeGapcPartIdentityKey, ResourcePath } from '../utils/resources';
import { createGapcAttributes } from './attributes';
import { createMeasurements } from './measurement';
import { createUvdbProperty } from './properties';

export const createGapcBase = (id: string | null | undefined, ctx: Context): GapcBase | null => {
	if (!id) {
		return null;
	}

	const gapcProperty = ctx.resources.get({ path: 'gapc_properties', id });
	if (!gapcProperty) {
		return null;
	}

	return {
		id: gapcProperty.id,
		name: gapcProperty.name
	};
};

export const createGapcBrand = (id: string | null | undefined, ctx: Context): GapcBrand | null => {
	if (!id) {
		return null;
	}

	const gapcProperty = ctx.resources.get({ path: 'gapc_properties', id });
	if (!gapcProperty) {
		return null;
	}

	if (gapcProperty.kind !== 'Brand') {
		return null;
	}

	return {
		id: gapcProperty.id,
		name: gapcProperty.name,
		isOem: gapcProperty.is_oem
	};
};

export const createGapcBases = (items: string[] = [], ctx: Context): GapcBase[] => {
	return items
		.map(item => {
			const id = getId(item);
			return createGapcBase(id, ctx);
		})
		.filter((item): item is GapcBrand => !!item);
};

export const createGapcBrands = (items: string[] = [], ctx: Context): GapcBrand[] => {
	return items
		.map(item => {
			const id = getId(item);
			return createGapcBrand(id, ctx);
		})
		.filter((item): item is GapcBrand => !!item);
};

export const createGapcPartType = (
	id: string | null | undefined,
	ctx: Context
): GapcPartType | null => {
	if (!id) {
		return null;
	}

	const gapcProperty = ctx.resources.get({ path: 'gapc_properties', id });
	if (!gapcProperty) {
		return null;
	}

	if (gapcProperty.kind !== 'PartType') {
		return null;
	}

	const aliases = gapcProperty.aliases?.map(({ name }) => name) ?? [];
	const categorizations = gapcProperty.categorizations
		.map(c => {
			const category = createGapcCategory(c.category_id, ctx);
			const subcategory = createGapcBase(c.subcategory_id, ctx);
			if (!category || !subcategory) {
				return null;
			}
			return {
				category,
				subcategory
			};
		})
		.filter(isDefined);

	const uvdbPropertyKeys = getUvdbSuidTypes(gapcProperty.uvdb_property_prefixes ?? []);

	return {
		id: gapcProperty.id,
		name: gapcProperty.name,
		categorizations,
		uvdbPropertyKeys,
		aliases
	};
};

export const createGapcCategory = (
	id: string | null | undefined,
	ctx: Context
): GapcCategory | null => {
	if (!id) {
		return null;
	}

	const gapcProperty = ctx.resources.get({ path: 'gapc_properties', id });
	if (!gapcProperty) {
		return null;
	}

	if (gapcProperty.kind !== 'Category') {
		return null;
	}

	const vehicleType = createUvdbProperty(gapcProperty.vehicle_type_id, ctx);

	return {
		id: gapcProperty.id,
		name: gapcProperty.name,
		vehicleTypeId: gapcProperty.vehicle_type_id,
		vehicleType
	};
};

export const createGapcPartTypes = (items: string[] = [], ctx: Context): GapcPartType[] => {
	return items
		.map(item => {
			const id = getId(item);
			return createGapcPartType(id, ctx);
		})
		.filter((item): item is GapcPartType => !!item);
};

export const createGapcProperties = (
	gapcPropertyIds: string[],
	ctx: Context
): Record<string, GapcProperty> => {
	return gapcPropertyIds.reduce(
		(acc, key) => {
			const value = ctx.resources.get({ path: 'gapc_properties', id: key });
			if (!value) {
				return acc;
			}

			switch (value.kind) {
				case 'Attribute': {
					acc[key] = {
						id: value.id,
						name: value.name,
						kind: value.kind,
						dataType: value.data_type
					};
					break;
				}
				case 'Brand': {
					const brand = createGapcBrand(value.id, ctx);
					if (!brand) {
						break;
					}

					acc[key] = {
						kind: value.kind,
						...brand
					};
					break;
				}
				case 'PartType': {
					const partType = createGapcPartType(value.id, ctx);
					if (!partType) {
						break;
					}

					acc[key] = {
						kind: value.kind,
						...partType
					};
					break;
				}
				case 'Category': {
					const category = createGapcCategory(value.id, ctx);
					if (!category) {
						break;
					}

					acc[key] = {
						kind: value.kind,
						...category
					};
					break;
				}
				default: {
					acc[key] = {
						id: value.id,
						name: value.name,
						kind: value.kind
					};
					break;
				}
			}

			return acc;
		},
		{} as Record<string, GapcProperty>
	);
};

export const createBaseGapcProperties = (items: string[], ctx: Context): BaseGapcProperties => {
	const base: BaseGapcProperties = {
		gapcPositions: [],
		gapcPartType: null,
		gapcSubcategory: null,
		gapcCategory: null
	};

	for (const property of items) {
		const propertyType = getGapcSuidType(property);
		switch (propertyType) {
			case 'GapcPosition': {
				const position = createGapcBase(property, ctx);
				if (!position) {
					continue;
				}

				base.gapcPositions.push(position);
				break;
			}
			case 'GapcCategory':
				base.gapcCategory = createGapcCategory(property, ctx);
				break;
			case 'GapcPartType':
				base.gapcPartType = createGapcPartType(property, ctx);
				break;
			case 'GapcSubcategory':
				base.gapcSubcategory = createGapcBase(property, ctx);
				break;
			default:
				continue;
		}
	}

	return base;
};

export const createPartSlot = (slot: PartSlotRaw, ctx: Context): PartSlot => {
	const properties = createBaseGapcProperties(
		[slot.gapc_part_type_id, slot.gapc_position_id].filter(isDefined),
		ctx
	);
	return {
		gapcPartType: properties.gapcPartType,
		gapcPosition: properties.gapcPositions.at(0)
	};
};

export const createGapcPart = (
	{
		gapc_attributes,
		measurement,
		gapc_properties,
		gapc_brand_id,
		original_mpn,
		mpn,
		...rest
	}: GapcPartRaw,
	ctx: Context
): GapcPart => {
	const part = recursiveToCamel(rest);
	const gapcBrand = createGapcBrand(gapc_brand_id, ctx);
	const gapcAttributes = createGapcAttributes(gapc_attributes, ctx);
	const properties = createBaseGapcProperties(gapc_properties, ctx);
	return {
		partIdentity: encodeGapcPartIdentityKey({
			gapcBrandId: gapc_brand_id,
			mpn
		}),
		mpn: original_mpn ?? mpn,
		gapcBrand,
		gapcAttributes,
		measurement: createMeasurements(measurement),
		...properties,
		...part
	};
};

export const createGapcDiagrams = (items: string[], ctx: Context): GapcDiagram[] =>
	items
		.map(key => {
			const path = ResourcePath.create<'gapc_diagrams'>(key);
			if (!path) {
				return null;
			}

			const value = ctx.resources.get(path);
			if (!value) {
				return null;
			}

			const { partSlots, references, ...rest } = recursiveToCamel(value);

			return {
				...rest,
				references: references ?? [],
				partSlots: partSlots.map(({ id, code, hotspots, parts, assemblies, segments }) => ({
					id,
					code,
					hotspots,
					parts: parts.map(part => createGapcAssemblyPart(part)),
					assemblies,
					segments: segments ?? []
				}))
			};
		})
		.filter(isDefined);

export const createGapcAssemblyPart = ({
	gapcPartIdentity,
	...partRest
}: NormalizedGapcAssemblyPart): GapcAssemblyPart => {
	return {
		...partRest,
		...gapcPartIdentity,
		partIdentity: gapcPartIdentity ? encodeGapcPartIdentityKey(gapcPartIdentity) : null
	};
};

export const createPartSelectionContext = (
	context: PartSelectionContextsRaw | null,
	ctx: Context
): PartSelectionContexts | null => {
	if (!context) {
		return null;
	}

	return context.map(contextRaw => {
		const gapcBrand = createGapcBrand(contextRaw.gapc_brand_id, ctx);
		const gapcPosition = createGapcBase(contextRaw.gapc_position_id, ctx);
		const gapcPartType = createGapcPartType(contextRaw.gapc_part_type_id, ctx);

		return {
			description: contextRaw.description ?? null,
			mpn: contextRaw.mpn ?? null,
			gapcBrand,
			gapcPosition,
			gapcPartType
		};
	});
};

import { Badge } from '@/app/atoms/badge';
import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import { useMeasurement } from '@/app/hooks/use-measure';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps, InheritableProps } from '@/types/utilties';
import { ReactComponent as AiLogo } from '@assets/parts/ai-logo.svg';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import { Divider, Loader, Popover } from '@mantine/core';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { isNil, sortBy, values } from 'lodash-es';
import { ReactNode, Suspense, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Control, Controller } from 'react-hook-form';
import { Camera, MOUSE, TOUCH } from 'three';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { DEFAULT_DISTANCE_Z, MAX_MIN_DISTANCE_Z, MESH_SCALE } from '../../constants';
import { useImageSizes } from '../../hooks/use-image-dimensions';
import { useSearchValue } from '../../hooks/use-search-value';
import {
	Diagram,
	DiagramPartSlot,
	DiagramPartSlotAssemblyInfo,
	DiagramPartSlotReferenceInfo,
	PartsFormData,
	PartsSelection
} from '../../types';
import { vec3 } from '../../utils/geometry';
import { layoutPartSlotMeshes, meshAreaSortKey, meshKindSortKey } from '../../utils/mesh';
import { filterTokenMatch } from '../../utils/search';
import { groupVariants } from '../../utils/variant';
import { usePartsContext } from '../parts-context';
import { PartsAssemblyDisplay, PartsVariantDisplay, PartsVariantsDisplay } from '../parts-display';
import { PartsHotpot } from '../parts-hotspot';
import {
	PartsDiagramAssemblyHotspot,
	PartsDiagramBackground,
	PartsDiagramCodeLayer,
	PartsDiagramFallback,
	PartsDiagramLine,
	PartsDiagramSuspenseSegment
} from './mesh';

const optimalMeshScale = (ratio: number) => {
	const res = MESH_SCALE / (ratio * 1.5);
	return res;
};

type PartsDiagramRendererProps = InheritableProps<
	typeof Canvas,
	{
		diagram?: Diagram | null;
		hightlighted: Set<string>;
		selection: PartsFormData;
		zoom: number;
		hide?: boolean;
		actions: {
			part: {
				highlight: (partSlotIds: string[]) => void;
			};
			diagram: {
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
			controls: {
				start: (control: OrbitControlsImpl) => void;
				change: (control: Camera) => void;
			};
		};
		children?: ReactNode;
	}
>;

export const PartsDiagramRenderer = ({
	className,
	diagram,
	hightlighted,
	selection,
	actions,
	hide,
	zoom,
	...rest
}: PartsDiagramRendererProps) => {
	const q = useSearchValue();
	const [errors, setErrors] = useState<Record<string, Error>>({});
	const { value: nav } = useMeasurement('navigation-bar');
	const imageSize = useImageSizes(diagram?.image?.full ?? null);

	const sizes = useMemo(() => {
		if (!imageSize || !nav) {
			return null;
		}
		return {
			width: imageSize.naturalWidth,
			height: imageSize.naturalHeight,
			ratio: imageSize.naturalHeight / imageSize.naturalWidth,
			scale: optimalMeshScale(imageSize.naturalHeight / imageSize.naturalWidth)
		};
	}, [imageSize, nav]);

	const segmented = useMemo(
		() => diagram?.partSlots?.some(({ meshes }) => meshes.polygons.length > 0) ?? false,
		[diagram]
	);

	const error = useMemo(() => (diagram ? errors?.[diagram.image.full] : null), [errors, diagram]);

	const partSlots = useMemo(() => {
		if (!sizes || !diagram) {
			return { meshes: [], polygons: [] };
		}

		const { meshes, polygons } = layoutPartSlotMeshes(diagram, sizes);

		return {
			meshes: sortBy(meshes, ({ mesh }) => meshKindSortKey(mesh)),
			polygons: sortBy(polygons, ({ mesh }) => meshAreaSortKey(mesh))
		};
	}, [diagram, sizes]);

	return (
		<>
			<Canvas
				className={tlsx('w-full h-full overflow-hidden', className)}
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px - 5.5rem)`,
					maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 5.5rem)`
				}}
				{...rest}
			>
				{diagram && sizes && (
					<>
						<ErrorBoundary
							fallbackRender={() => (
								// todo (vincent) some images are not cors configured properly
								// this is decent fallback/render image as image (only caveat no hotspots)
								<PartsDiagramFallback
									key={diagram.id}
									url={diagram.image.full}
									ratio={sizes.ratio}
									scale={sizes.scale}
									filter={
										hide || !segmented ? 'opaque' : hightlighted.size > 0 ? 'dimmed' : 'background'
									}
								/>
							)}
							onError={err => setErrors(errors => ({ ...errors, [diagram.image.full]: err }))}
						>
							<PartsDiagramBackground
								key={diagram.id}
								url={diagram.image.full}
								ratio={sizes.ratio}
								scale={sizes.scale}
								filter={
									hide || !segmented ? 'opaque' : hightlighted.size > 0 ? 'dimmed' : 'background'
								}
							/>
						</ErrorBoundary>
						{!hide && (
							<>
								{partSlots.meshes.map(({ info, mesh }, index) => {
									const assemblies = info.kind === 'assembly' ? info.assemblies : [];
									const navigable = info.kind === 'reference';
									const highlighted = hightlighted.has(info.id);
									const added = assemblies.some(({ id }) => (selection[id]?.quantity ?? 0) > 0);
									const searched = q
										? assemblies.some(({ searchables }) =>
												searchables.some(searchable => filterTokenMatch(searchable, q))
											)
										: false;
									const onClick = () => actions.part.highlight([info.id]);

									switch (mesh.kind) {
										case 'whiteout': {
											return (
												<PartsDiagramCodeLayer
													key={`${info.id}-whiteout-${index}`}
													rect={mesh.rect}
												/>
											);
										}
										case 'line': {
											return (
												<PartsDiagramLine
													key={`${info.id}-line-${index}`}
													from={mesh.from}
													to={mesh.to}
													state={
														highlighted
															? 'highlighted'
															: added
																? 'added'
																: searched
																	? 'searched'
																	: navigable
																		? 'navigable'
																		: 'interactive'
													}
													dashed={!highlighted && !added}
													far={zoom < 0.9 && segmented}
												/>
											);
										}
										case 'hotspot': {
											return (
												<PartsDiagramAssemblyHotspot
													key={`${info.id}-hotspot-${index}`}
													code={info.code}
													kind={info.kind}
													point={mesh.point}
													checked={added}
													highlighted={highlighted}
													searched={searched}
													far={zoom < 0.9 && segmented}
													onClick={onClick}
												/>
											);
										}
									}
								})}
								{partSlots.polygons.map(({ mesh, infos }, index) => {
									const ids = infos.map(({ id }) => id);
									const assemblies = infos.flatMap(info =>
										info.kind === 'assembly' ? info.assemblies : []
									);
									const navigable = infos.every(({ kind }) => kind === 'reference');
									const highlighted = ids.some(id => hightlighted.has(id));
									const added = assemblies.some(({ id }) => (selection[id]?.quantity ?? 0) > 0);

									const searched = q
										? assemblies.some(({ searchables }) =>
												searchables.some(searchable => filterTokenMatch(searchable, q))
											)
										: false;

									return (
										<PartsDiagramSuspenseSegment
											key={`${ids.join('-')}-polygon-${index}`}
											url={diagram.image.full}
											ratio={sizes.ratio}
											scale={sizes.scale}
											polygon={mesh.polygon}
											position={vec3(0, 0, 0)}
											state={
												highlighted
													? 'highlighted'
													: added
														? 'added'
														: searched
															? 'searched'
															: navigable
																? 'navigable'
																: 'interactive'
											}
											renderOrder={partSlots.meshes.length + index}
											// decalOrder={highlighted ? 4 : 2}
											onClick={() => actions.part.highlight(ids)}
										/>
									);
								})}
							</>
						)}
					</>
				)}

				<OrbitControls
					position={[0, 0, 0]}
					ref={orbitControls => {
						if (!orbitControls) {
							return;
						}
						actions.controls.start(orbitControls);
					}}
					enablePan
					enableDamping
					enableRotate={false}
					zoomSpeed={1.25}
					dampingFactor={0.1}
					maxDistance={DEFAULT_DISTANCE_Z + MAX_MIN_DISTANCE_Z}
					minDistance={DEFAULT_DISTANCE_Z - MAX_MIN_DISTANCE_Z}
					mouseButtons={{
						LEFT: MOUSE.PAN,
						MIDDLE: MOUSE.PAN
					}}
					touches={{
						ONE: TOUCH.PAN,
						TWO: TOUCH.DOLLY_PAN
					}}
					onChange={event => {
						if (!event) {
							return;
						}
						actions.controls.change(event.target.object);
					}}
				/>
			</Canvas>

			{segmented && (
				<div className="absolute bottom-6 right-6 z-[5] select-none">
					<Popover width={200} withinPortal position="top-end">
						<Popover.Target>
							<Badge className="cursor-pointer" size="small" rounded variant="purple">
								<Badge.LeadingIcon as={AiLogo} />
								Interactive diagram
							</Badge>
						</Popover.Target>
						<Popover.Dropdown className="flex flex-col gap-2">
							<span className="text-xs text-gray-600 [text-wrap:pretty]">
								Part in this diagram are interactive / clickable
							</span>
							<Divider className="my-1" />
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3 bg-purple-100 rounded border-2 border-purple-600" />
								<span className="text-xs">Interactive part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3 bg-blue-100 rounded border-2 border-blue-600" />
								<span className="text-xs">Highlighted part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3 bg-emerald-100 rounded border-2 border-emerald-600" />
								<span className="text-xs">Added part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3 bg-amber-100 rounded border-2 border-amber-600" />
								<span className="text-xs">Part matching search</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3 bg-slate-100 rounded border-2 border-slate-600" />
								<span className="text-xs">Link to other diagrams</span>
							</div>
						</Popover.Dropdown>
					</Popover>
				</div>
			)}

			{!isNil(error) && (
				<div className="absolute bottom-6 right-6 z-[5]  select-none">
					<Popover width={200} withinPortal position="top-end">
						<Popover.Target>
							<Badge className="cursor-pointer" size="small" rounded variant="red">
								<Badge.LeadingIcon as={Cog6ToothIcon} />
								Fallback diagram
							</Badge>
						</Popover.Target>
						<Popover.Dropdown className="flex flex-col gap-2">
							<span className="text-xs text-gray-600 [text-wrap:pretty]">
								Encountered some issues this diagram, reverted to simple diagram
							</span>
						</Popover.Dropdown>
					</Popover>
				</div>
			)}
		</>
	);
};

export const PartsDiagramSuspenseRenderer = ({ className, ...rest }: PartsDiagramRendererProps) => (
	<Suspense
		fallback={
			<div key="parts-diagram-loader" className={tlsx('w-full grid place-items-center', className)}>
				<Loader variant="bars" />
			</div>
		}
	>
		<PartsDiagramRenderer className={className} {...rest} />
	</Suspense>
);

export type PartsDiagramViewProps = InheritableElementProps<
	'div',
	{
		partSlots: DiagramPartSlot[];
		control: Control<PartsFormData>;
		selection: PartsFormData;
		actions: {
			part: {
				highlight: (ids: string[]) => void;
			};
			diagram: {
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
		};
	}
>;

export const PartsDiagramView = ({
	partSlots,
	control,
	selection,
	className,
	style,
	actions,
	...rest
}: PartsDiagramViewProps) => {
	const { value: nav } = useMeasurement('navigation-bar');

	return (
		<div
			className={tlsx(
				'absolute z-10 top-4 right-4 flex flex-col gap-2.5 w-[26rem] overflow-auto',
				className
			)}
			style={{
				maxHeight: `calc(100dvh - ${nav?.height}px - 5.5rem)`,
				...style
			}}
			{...rest}
		>
			{partSlots.map(partSlot => (
				<PartsDiagramPartSlot
					key={partSlot.id}
					partSlot={partSlot}
					control={control}
					selection={selection}
					actions={actions}
				/>
			))}
		</div>
	);
};

export type PartsDiagramPartSlotProps = InheritableElementProps<
	'div',
	{
		partSlot: DiagramPartSlot;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		actions: {
			part: {
				highlight: (ids: string[]) => void;
			};
			diagram: {
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
		};
	}
>;

export const PartsDiagramPartSlot = ({
	partSlot,
	control,
	selection,
	actions,
	className,
	...rest
}: PartsDiagramPartSlotProps) => {
	switch (partSlot.kind) {
		case 'assembly': {
			return (
				<PartsDiagramPartSlotAssembly
					partSlot={partSlot}
					control={control}
					selection={selection}
					actions={actions}
					{...rest}
				/>
			);
		}
		case 'reference': {
			return (
				<PartsDiagramPartSlotReference
					partSlot={partSlot}
					selection={selection}
					actions={actions}
				/>
			);
		}
	}
};

export type PartsDiagramPartSlotAssemblyProps = InheritableElementProps<
	'div',
	{
		partSlot: DiagramPartSlotAssemblyInfo;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		actions: {
			part: {
				highlight: (ids: string[]) => void;
			};
		};
	}
>;

export const PartsDiagramPartSlotAssembly = ({
	partSlot,
	control,
	selection,
	actions,
	className,
	onClick: _,
	...rest
}: PartsDiagramPartSlotAssemblyProps) => {
	const variants = groupVariants(partSlot.assemblies);
	return variants.map(variant => {
		if (variant.length === 1) {
			const assembly = variant[0];
			return (
				<Controller
					key={assembly.id}
					control={control}
					name={assembly.id}
					render={({ field }) => {
						const checked = (selection[assembly.id]?.quantity ?? field?.value?.quantity ?? 0) > 0;
						return (
							<PartsAssemblyDisplay
								className={tlsx('w-full', className)}
								assembly={assembly}
								checkbox={{
									checked,
									onChange: e => {
										if (!assembly) {
											return;
										}
										const data: PartsSelection = {
											mpn: assembly.part.mpn,
											gapcBrandId: assembly.part.gapcBrandId,
											partSlotIds: assembly.partSlotIds,
											quantity: e.target.checked ? 1 : 0,
											assemblyId: assembly.id,
											description: assembly.description,
											hcas: assembly.hcas,
											order: values(selection).filter(s => (s?.quantity ?? 0) > 0).length
										};
										field.onChange(data);
									}
								}}
								onClick={() => actions.part.highlight([partSlot.id])}
								{...rest}
							/>
						);
					}}
				/>
			);
		}

		return (
			<PartsVariantsDisplay
				className={tlsx('w-full', className)}
				variants={variant}
				onClick={() => actions.part.highlight([partSlot.id])}
				render={variant => (
					<Controller
						key={variant.id}
						control={control}
						name={variant.id}
						render={({ field }) => {
							const checked = (selection[variant.id]?.quantity ?? field?.value?.quantity ?? 0) > 0;
							return (
								<PartsVariantDisplay
									variant={variant}
									checkbox={{
										checked,
										onChange: e => {
											if (!variant) {
												return;
											}
											const data: PartsSelection = {
												mpn: variant.part.mpn,
												gapcBrandId: variant.part.gapcBrandId,
												partSlotIds: variant.partSlotIds,
												quantity: e.target.checked ? 1 : 0,
												assemblyId: variant.id,
												description: variant.description,
												hcas: variant.hcas,
												order: values(selection).filter(s => (s?.quantity ?? 0) > 0).length
											};
											field.onChange(data);
										}
									}}
								/>
							);
						}}
					/>
				)}
				{...rest}
			/>
		);
	});
};

export type PartsDiagramPartSlotReferenceProps = InheritableElementProps<
	'div',
	{
		partSlot: DiagramPartSlotReferenceInfo;
		selection: PartsFormData;
		actions: {
			diagram: {
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
		};
	}
>;

export const PartsDiagramPartSlotReference = ({
	partSlot,
	selection,
	actions,
	className,
	onClick: _,
	...rest
}: PartsDiagramPartSlotReferenceProps) => {
	const context = usePartsContext();
	const diagrams = useMemo(
		() =>
			context.diagrams.filter(({ diagram }) =>
				partSlot.diagrams.some(({ id }) => id === diagram.id)
			),
		[context]
	);

	return (
		<div
			className={tlsx('flex flex-col p-4 rounded-lg shadow-sm border bg-white', className)}
			{...rest}
		>
			<span className="font-medium px-1 mb-3">Link to other diagrams</span>
			{diagrams.map(({ cut, category, diagram }) => (
				<button
					type="button"
					key={diagram.id}
					className="flex items-center w-full gap-3 mb-2 last:mb-0"
					onClick={() => actions.diagram.jump(cut, category, diagram.id)}
				>
					<ImageWithSkeleton className="size-20 rounded border" src={diagram.image.thumb} />
					<div className="flex flex-col items-start justify-center gap-2 h-20">
						<PartsHotpot code={partSlot.code} kind="reference" />
						<span className="text-sm font-medium text-start">
							{diagram.description} ({diagram.code})
						</span>
					</div>
				</button>
			))}
		</div>
	);
};

import { minBy } from 'lodash-es';
import { Vector2 } from 'three';
import { slice } from '.';
import { MESH_SCALE } from '../constants';
import {
	Diagram,
	DiagramHotspotMesh,
	DiagramLineMesh,
	DiagramMesh,
	DiagramPartSlotMesh,
	DiagramPolygonMesh,
	DiagramWhiteoutMesh,
	Slice
} from '../types';
import {
	line2Distance,
	line2ToCenterPoint,
	polygonArea,
	polygonOverlap,
	polygonToCenterPoint,
	relativeToCenter
} from './geometry';

export const meshKindSortKey = (mesh: DiagramMesh) => {
	const key: Record<DiagramMesh['kind'], number> = {
		whiteout: 0,
		line: 1,
		hotspot: 2,
		polygon: 3
	};

	return key[mesh.kind] ?? 4;
};

export const meshAreaSortKey = (mesh: DiagramMesh) => {
	if (mesh.kind !== 'polygon') {
		return 0;
	}

	return -polygonArea(mesh.polygon);
};

export const relativeMesh = <Mesh extends DiagramMesh>(
	mesh: Mesh,
	sizes: { width: number; height: number; ratio: number; scale?: number }
): Mesh => {
	switch (mesh.kind) {
		case 'whiteout': {
			return {
				...mesh,
				rect: slice([
					relativeToCenter(mesh.rect[0], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[1], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[2], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[3], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				])
			};
		}
		case 'line': {
			return {
				...mesh,
				from: mesh.from.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				),
				to: mesh.to.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				)
			};
		}
		case 'polygon': {
			return {
				...mesh,
				polygon: mesh.polygon.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				)
			};
		}
		case 'hotspot': {
			return {
				...mesh,
				point: relativeToCenter(mesh.point, sizes.width, sizes.height).multiplyScalar(
					sizes.scale ?? MESH_SCALE
				)
			};
		}
	}
};

export const drawLines = (hotspots: Slice<Vector2, 2>[], polygons: Vector2[][]) => {
	// missing polygons or hotspots, lines is not needed
	if (polygons.length === 0 || hotspots.length === 0) {
		return [];
	}

	// 1 hotspot multiple polygons (bad segments split into multiple, maybe actual multiple polygons for 1 hotspot?)
	if (hotspots.length === 1) {
		const from = line2ToCenterPoint(hotspots[0]);
		const polygon =
			minBy(polygons, polygon => line2Distance([from, polygonToCenterPoint(polygon)])) ??
			polygons[0];
		return [slice([[line2ToCenterPoint(hotspots[0])], polygon])];
	}

	// more hotspots than polygons
	if (hotspots.length > polygons.length) {
		return polygons.map(polygon => {
			const to = polygonToCenterPoint(polygon);
			const hotspot =
				minBy(hotspots, hotspot => line2Distance([line2ToCenterPoint(hotspot), to])) ?? hotspots[0];
			return slice([[line2ToCenterPoint(hotspot)], polygon]);
		});
	}

	return hotspots.map(hotspot => {
		const from = line2ToCenterPoint(hotspot);
		const polygon =
			minBy(polygons, polygon => line2Distance([from, polygonToCenterPoint(polygon)])) ??
			polygons[0];
		return slice([[line2ToCenterPoint(hotspot)], polygon]);
	});
};

const groupPolygonMeshes = (polygons: DiagramPartSlotMesh<DiagramPolygonMesh>[]) => {
	const existings = [] as typeof polygons;
	for (const polygon of polygons) {
		let hasOverlap = false;
		for (const existing of existings) {
			const overlap = polygonOverlap(polygon.mesh.polygon, existing.mesh.polygon);

			if (overlap >= 0.9 && overlap <= 1) {
				existing.infos.push(...polygon.infos);
				hasOverlap = true;
			}
		}
		if (!hasOverlap) {
			existings.push(polygon);
		}
	}

	return existings;
};

export const layoutPartSlotMeshes = (
	diagram: Diagram,
	sizes: { width: number; height: number; ratio: number; scale?: number }
) => {
	const meshes = {
		whiteouts: [] as DiagramPartSlotMesh<DiagramWhiteoutMesh>[],
		lines: [] as DiagramPartSlotMesh<DiagramLineMesh>[],
		polygons: [] as DiagramPartSlotMesh<DiagramPolygonMesh>[],
		hotspots: [] as DiagramPartSlotMesh<DiagramHotspotMesh>[]
	};
	for (const {
		meshes: { whiteouts, lines, polygons, hotspots },
		...partSlot
	} of diagram.partSlots) {
		meshes.whiteouts.push(
			...whiteouts.map(mesh => relativeMesh(mesh, sizes)).map(mesh => ({ mesh, info: partSlot }))
		);
		meshes.lines.push(
			...lines.map(mesh => relativeMesh(mesh, sizes)).map(mesh => ({ mesh, info: partSlot }))
		);
		meshes.polygons.push(
			...polygons.map(mesh => relativeMesh(mesh, sizes)).map(mesh => ({ mesh, infos: [partSlot] }))
		);
		meshes.hotspots.push(
			...hotspots.map(mesh => relativeMesh(mesh, sizes)).map(mesh => ({ mesh, info: partSlot }))
		);
	}

	return {
		meshes: [...meshes.whiteouts, ...meshes.lines, ...meshes.hotspots],
		polygons: groupPolygonMeshes(meshes.polygons)
	};
};

import { formatSelectionContextReflect } from '@/app/utils/part';
import {
	GetJobSupplyRecommendationsResult,
	JobPart,
	PartSupplyOffer,
	SupplyVendor
} from '@/sdk/lib';
import { isDefined } from '@partly/js-ex';
import { draft_order, part_slot, supply } from '@/sdk/reflect/reflect';
import { encodeGapcPartIdentityKey } from '@/sdk/reflect/resource';
import { match } from '@/types/match';
import { cloneDeep, intersection, remove } from 'lodash-es';
import { UseFormReturn } from 'react-hook-form';
import { v4 } from 'uuid';
import { isBefore, roundToNearestHours } from 'date-fns';
import {
	AddItemPayload,
	DraftOrderItemModel,
	DraftOrderSelection,
	JobPartItemSelection,
	OrderRequestModel
} from './models';
import { compareOfferIds, createPartSelectionContexts } from './supply';

export const restoreOrderRequestModel = (
	request: draft_order.exp.DraftOrder
): OrderRequestModel => {
	const items: DraftOrderItemModel[] = request.items.map(item => {
		return {
			...item,
			id: item.id,
			local_id: item.id,
			name:
				item.buyable.type === 'Listing'
					? item.buyable.offer.type === 'Kit'
						? item.buyable.offer.listing.name
						: formatSelectionContextReflect(item.context)
					: item.buyable.description,
			buyable: createBuyableOffer(item.buyable)
		};
	});

	return {
		...request,
		local_id: sanitiseOrderId(request.id),
		// We have re-purposed this field as core server now does the automation.
		instant_accept: request.attempt_auto_transition_order.enabled,
		order_id: request.id,
		items
	};
};

export const sanitiseOrderId = (id: string): string => {
	// We use the id as a key in the form state. We don't want
	// user input to accidently mess with paths so we santise any
	// orders stored to not have full stops (which can mess with paths).
	return id.replaceAll('.', '');
};

export const createOrderRequestItem = (
	offer: PartSupplyOffer,
	context: part_slot.exp.PartSelectionContexts | null,
	quantity: number
): DraftOrderItemModel => {
	const buyableOffer: supply.SupplyItemOffer =
		(offer.offer.type === 'Product' && {
			type: 'Product',
			offer_id: offer.offer.offerId,
			listing_id: offer.offer.listing.id
		}) ||
		(offer.offer.type === 'Kit' && {
			type: 'Kit',
			listing_id: offer.offer.listing.id,
			offer_ids: offer.offer.offerIds
		}) ||
		(() => {
			throw new Error();
		})();

	return {
		id: null,
		local_id: v4(),
		/** TODO don't show kit name on SupplyVendorOption, show the list of context instead, then delete this field */
		name:
			offer.offer.type === 'Kit'
				? offer.offer.listing.name
				: formatSelectionContextReflect(context),
		status: 'Pending',
		order_separately: false,
		arrival_at: offer.shipping?.eta ? new Date(offer.shipping?.eta).valueOf() : null,
		context,
		quantity,
		price: offer.price.price,
		grade: offer.grade ?? null,
		buyable: {
			type: 'Listing',
			offer: buyableOffer
		}
	};
};

const createBuyableOffer = (
	buyable: draft_order.exp.DraftOrderItemBuyable
): draft_order.DraftOrderItemBuyable => {
	if (buyable.type === 'Listing') {
		if (buyable.offer.type === 'Product') {
			return {
				type: 'Listing',
				offer: {
					type: 'Product',
					offer_id: buyable.offer.offer_id,
					listing_id: buyable.offer.listing.id
				}
			};
		}

		return {
			type: 'Listing',
			offer: {
				type: 'Kit',
				listing_id: buyable.offer.listing.id,
				offer_ids: buyable.offer.offer_ids
			}
		};
	}

	return {
		type: 'External',
		description: buyable.description,
		identity: buyable.identity
	};
};

const isEditable = (order: OrderRequestModel): boolean => {
	return match(order.status, {
		Draft: () => true,
		Processing: () => false,
		Processed: () => true,
		Cancelled: () => false,
		Finalised: () => false
	});
};

export const getMinDeliveryDate = (orders: OrderRequestModel[]): Date => {
	const relevantOrders = orders.filter(isEditable);
	const itemDates: Date[] = [];
	for (const order of relevantOrders) {
		for (const item of order.items) {
			// check if arrial date is set and if its a back order of item has been rejected
			if (
				!item.arrival_at ||
				item.order_separately ||
				(typeof item.status === 'object' && 'Rejected' in item.status)
			) {
				continue;
			}

			const arrivalAt = new Date(item.arrival_at);
			itemDates.push(arrivalAt);
		}
	}

	const latestDate = getLatestDate(itemDates);
	const now = new Date();

	if (!latestDate || isBefore(latestDate, now)) {
		return now;
	}

	return latestDate;
};

export const getDeliveryDateFromOrders = (
	orders: OrderRequestModel[],
	defaultDeliveryDate: Date
) => {
	// We only want to consider draft orders where decisions can still be made
	const relevantOrders = orders.filter(
		order =>
			match(order.status, {
				Cancelled: () => false,
				Finalised: () => false,
				Draft: () => true,
				Processing: () => true,
				Processed: () => true
			}),
		() => false
	);

	const orderDates: Date[] = [];
	for (const order of relevantOrders) {
		// It is possible that an order has come back processed, but
		// with all the items rejected. In this case we want to exclude the
		// order + items from the delivery date calculation.
		let hasOrderableItems = false;
		for (const item of order.items) {
			if (typeof item.status === 'object' && 'Rejected' in item.status) {
				continue;
			}

			// Some items won't have an arrival date, this is perfectly valid
			if (!item.arrival_at) {
				continue;
			}

			// Some items are ordered separately and don't
			// affect the delivery date so we can skip these.
			if (item.order_separately) {
				continue;
			}

			// At least one item is orderable
			hasOrderableItems = true;
			const arrivalAt = new Date(item.arrival_at);
			orderDates.push(arrivalAt);
		}

		if (hasOrderableItems) {
			if (order.status === 'Draft') {
				// If the order is still in draft, then the delivery date
				// is derrived from the global selected delivery date.
				orderDates.push(defaultDeliveryDate);
				continue;
			}

			// Otherwise use the saved delivery date
			const targetDeliveryDate = new Date(order.target_deliver_before_timestamp);
			orderDates.push(targetDeliveryDate);
		}
	}

	if (orderDates.length === 0) {
		// If there is no orders, then we can just default to
		// the nearest hour.
		return getNearestHour();
	}

	return getLatestDate(orderDates);
};

export const getNearestHour = (): Date => {
	const date = new Date();
	return roundToNearestHours(date, { roundingMethod: 'ceil' });
};

export const getDeliveryDateFromSelection = (selection: DraftOrderSelection): Date => {
	const models = Object.values(selection.draft_orders);
	const earliestDeliveryDatae = getDeliveryDateFromOrders(models, selection.delivery_date);

	// It is possible that the user has manually selected a
	// date that is later than the earliest possible. We move
	// the date forward automatically, but never backwards
	// automatically.
	if (earliestDeliveryDatae < selection.delivery_date) {
		return selection.delivery_date;
	}

	return earliestDeliveryDatae;
};

export const createItemContext = (
	part: JobPart | null | undefined
): part_slot.exp.PartSelectionContexts | null => {
	if (!part) {
		return null;
	}

	const context: part_slot.exp.PartSelectionContext = {
		description: part.description,
		mpn: part.mpn,
		gapc_brand: null,
		gapc_part_type: null,
		gapc_position: null,
		hcas: part.assembly?.hcas ?? null
	};

	if (part.partSlot?.gapcPartType) {
		context.gapc_part_type = {
			id: part.partSlot.gapcPartType.id,
			name: part.partSlot.gapcPartType.name,
			aliases: [],
			categorizations: [],
			gapc_properties: [],
			uvdb_property_prefixes: []
		};
	}

	if (part.partSlot?.gapcPosition) {
		context.gapc_position = {
			id: part.partSlot.gapcPosition.id,
			name: part.partSlot.gapcPosition.name
		};
	}

	if (part.gapcBrand) {
		context.gapc_brand = {
			id: part.gapcBrand.id,
			name: part.gapcBrand.name,
			is_oem: part.gapcBrand.isOem
		};
	}

	return [context];
};

export const contextToGapcPartIdentities = (
	context: part_slot.exp.PartSelectionContexts | null
): string[] | null => {
	if (!context) {
		return null;
	}

	return context
		.map(jobItemContext => {
			const { mpn } = jobItemContext;
			const gapc_brand_id = jobItemContext?.gapc_brand?.id;
			if (!mpn || !gapc_brand_id) {
				return null;
			}
			return encodeGapcPartIdentityKey({
				mpn: mpn!,
				gapc_brand_id: gapc_brand_id!
			});
		})
		.filter(isDefined);
};

export const createNewOrderRequest = (
	vendor: SupplyVendor,
	items: DraftOrderItemModel[]
): OrderRequestModel => {
	return {
		status: 'Draft',
		created_at: new Date().valueOf(),
		instant_accept: false,
		updated_at: null,
		local_id: v4(),
		vendor: {
			Partner: vendor
		},
		target_deliver_before_timestamp: new Date().valueOf(),
		vendor_notes: null,
		items,
		estimator_notes: null,
		images: [],
		order_id: null
	};
};

export const applyOfferSelection = (
	prev: JobPartItemSelection | null,
	next: JobPartItemSelection,
	form: UseFormReturn<DraftOrderSelection>,
	recommendations: GetJobSupplyRecommendationsResult
) => {
	const selection = form.getValues();
	const offer = recommendations.offers[next.offerId];
	if (!offer) {
		return;
	}

	const updateDeliveryDate = () => {
		const latestState = form.getValues();
		const deliveryDate = getDeliveryDateFromSelection(latestState);
		form.setValue('delivery_date', deliveryDate);
	};

	// Step 1: If we have an existing selection for the part, we
	// need to remove it from that order.
	if (prev) {
		const prevOffer = recommendations.offers[prev.offerId];
		if (prevOffer) {
			const existingRequest = Object.values(selection.draft_orders).find(
				order => order.vendor.Partner.id === prevOffer.vendor.id && order.status === 'Draft'
			);

			if (existingRequest) {
				const existingItem = existingRequest.items.findIndex(
					item =>
						item.buyable.type === 'Listing' && compareOfferIds(prevOffer.offer, item.buyable.offer)
				);

				if (existingItem !== -1) {
					existingRequest.items.splice(existingItem, 1);
					if (existingRequest.items.length === 0) {
						delete selection.draft_orders[existingRequest.local_id];
						form.setValue(`draft_orders`, selection.draft_orders);
					} else {
						form.setValue(`draft_orders.${existingRequest.local_id}`, existingRequest);
					}
				}
			}
		}
	}

	// Step 2: Check if we already have a draft order for this vendor
	// that we can re-use.
	const existingRequest = Object.values(selection.draft_orders).find(
		order => order.vendor.Partner.id === offer.vendor.id && order.status === 'Draft'
	);

	// Step 3: Find the job parts that this offer is for, we need this for
	// the context + default quantity.
	const context = offer.gapcParts
		.map(gapcPart =>
			recommendations.parts.find(
				part =>
					gapcPart.gapcBrand?.id &&
					part.part.partIdentity ===
						encodeGapcPartIdentityKey({ gapc_brand_id: gapcPart.gapcBrand.id, mpn: gapcPart.mpn })
			)
		)
		.filter(isDefined)
		.map(jobPart => createItemContext(jobPart.part))
		.filter(isDefined)
		.flatMap(ctx => ctx);
	if (context.length === 0) {
		return;
	}

	// Step 4: Create a new item for the draft order
	const newItem = createOrderRequestItem(offer, context, next.quantity);

	// Step 5: If we don't have an existing request, create a new one
	// with a single item.
	if (!existingRequest) {
		const newOrder = createNewOrderRequest(offer.vendor, [newItem]);
		form.setValue(`draft_orders.${newOrder.local_id}`, newOrder);
		updateDeliveryDate();
		return;
	}

	// Step 6: If we do have an existing request, then we need to update.
	// Start by finding the existing item, supply will always be a listing
	// so we can compare by the offer id.
	const existingItemI = () =>
		existingRequest.items.findIndex(
			item => item.buyable.type === 'Listing' && compareOfferIds(offer.offer, item.buyable.offer)
		);
	const existingItem = existingRequest.items[existingItemI()];

	// Step 7: In the case that the part the user selected is a kit, it can
	// fulfill multiple job parts (not just the current one). We should keep
	// things simple and remove the redundant items for these job parts - because
	// the kit will now fulfill those job parts.
	remove(
		existingRequest.items,
		item =>
			item !== existingItem &&
			intersection(
				contextToGapcPartIdentities(item.context ?? []),
				offer.gapcParts.map(jobPart => jobPart.partIdentity)
			).length > 0
	);

	// Step 8: If it is a new item then add it and return early.
	if (!existingItem) {
		existingRequest.items.push(newItem);
		form.setValue(`draft_orders.${existingRequest.local_id}`, existingRequest);
		updateDeliveryDate();
		return;
	}

	// Step 9: Remove the item if the quantity is 0, then validate
	// that the order can still exist.
	if (next.quantity === 0) {
		existingRequest.items.splice(existingItemI(), 1);

		if (existingRequest.items.length === 0) {
			delete selection.draft_orders[existingRequest.local_id];
		}

		form.setValue(`draft_orders`, selection.draft_orders);
		updateDeliveryDate();
		return;
	}

	// Step 10: Lastly update the quantity of the existing item
	// and update the form.
	existingRequest.items[existingItemI()] = {
		...existingItem,
		quantity: next.quantity
	};

	form.setValue(`draft_orders.${existingRequest.local_id}`, existingRequest);
	updateDeliveryDate();
};

export const getLatestDate = (dates: Date[]): Date => {
	return dates.reduce((acc, date) => {
		if (date > acc) {
			return date;
		}

		return acc;
	}, dates[0]);
};

export const restoreOrderSelection = (
	selection: DraftOrderSelection,
	draft_orders: draft_order.exp.DraftOrder[]
): DraftOrderSelection => {
	for (const draftOrder of draft_orders) {
		const model = restoreOrderRequestModel(draftOrder);
		selection.draft_orders[model.local_id] = restoreOrderRequestModel(draftOrder);
	}

	const models = Object.values(selection.draft_orders);
	const earliestDeliveryDate = getDeliveryDateFromOrders(models, selection.delivery_date);

	// We restore whatever the user has selected at
	// the delivery date. We don't want the check for existing
	// selection hence we don't use getDeliveryDateFromSelection.
	selection.delivery_date = earliestDeliveryDate;

	return selection;
};

export const getLatestPossibleDeliveryDateFromSelection = (
	selection: DraftOrderSelection
): Date => {
	const dates = Object.values(selection.draft_orders)
		.filter(order => order.status !== 'Finalised')
		.flatMap(order => {
			const itemDates = order.items
				.filter(item => !item.order_separately)
				.map(item => (item.arrival_at ? new Date(item.arrival_at) : new Date()));

			return itemDates;
		});

	return getLatestDate(dates);
};

export const createSelectionFromSupply = (
	selection: DraftOrderSelection,
	recommendation: GetJobSupplyRecommendationsResult
): DraftOrderSelection => {
	const offersByVendor = recommendation.recommendations[0].offers.reduce(
		(acc, offerId) => {
			const offer = recommendation.offers[offerId];
			if (!offer) {
				return acc;
			}

			const vendorId = offer.vendor.id;
			if (!acc[vendorId]) {
				acc[vendorId] = { vendor: offer.vendor, offers: [] };
			}

			acc[vendorId].offers.push(offer);

			return acc;
		},
		{} as Record<string, { vendor: SupplyVendor; offers: PartSupplyOffer[] }>
	);

	const jobPartMap = recommendation.parts.reduce(
		(acc, jobPart) => {
			acc[jobPart.part.partIdentity] = jobPart.part;
			return acc;
		},
		{} as Record<string, JobPart>
	);

	for (const vendorOffers of Object.values(offersByVendor)) {
		const items = vendorOffers.offers
			.map(offer => {
				// It's possible in the future to have two items that fulfill the same
				// part (kit), if the recommendation engine returns two offers that can
				// fulfill the same part. For now, keep it simple and don't auto select a
				// kit - let the user do it themselves.
				// https://www.notion.so/partly/Repair-app-recommendations-should-support-kits-1222cf966862806186cceb00bc131576
				if (offer.gapcParts.length !== 1) {
					return null;
				}

				const jobPart = jobPartMap[offer.gapcParts[0].partIdentity];
				const context = createItemContext(jobPart);
				const item = createOrderRequestItem(offer, context, jobPart?.quantity ?? 1);

				return item;
			})
			.filter(isDefined);

		const newOrderRequest = createNewOrderRequest(vendorOffers.vendor, items);
		selection.draft_orders[newOrderRequest.local_id] = newOrderRequest;
	}

	selection.delivery_date = getDeliveryDateFromSelection(selection);

	return selection;
};

export const onAddExternalItem = (
	selection: DraftOrderSelection,
	data: AddItemPayload,
	form: UseFormReturn<DraftOrderSelection>
) => {
	const existingOrder = Object.values(selection.draft_orders).find(
		order => order.vendor.Partner.id == data.vendor.id && order.status === 'Draft'
	);
	if (!existingOrder) {
		const newOrder = createNewOrderRequest(data.vendor, [data.item]);
		form.setValue(`draft_orders.${newOrder.local_id}`, newOrder);
		return;
	}

	existingOrder.items.push(data.item);
	form.setValue(`draft_orders.${existingOrder.local_id}`, existingOrder);
};

type RemoteOrderIds = Map<string, Map<string, string>>;

// todo: this method could do with a refactor
export const buildIngestFromSelection = (
	selection: DraftOrderSelection,
	remoteRequests: draft_order.exp.DraftOrder[],
	supplyHashId: string,
	instantAccept: boolean
): draft_order.DraftOrderIngest[] => {
	// If we have any draft orders, only send those to the server.
	// Otherwise, send everything.
	let values = Object.values(selection.draft_orders);
	const hasDrafts = values.some(order => order.status === 'Draft');
	values = values.filter(order => {
		if (hasDrafts) {
			return order.status === 'Draft';
		}

		return true;
	});

	remoteRequests = remoteRequests.filter(order => {
		if (hasDrafts) {
			return order.status === 'Draft';
		}

		return true;
	});

	const remoteIds = remoteRequests.reduce((acc, request) => {
		if (request.status !== 'Draft') {
			return acc;
		}

		acc.set(
			request.id,
			request.items.reduce((acc, item) => {
				acc.set(item.id, item.id);
				return acc;
			}, new Map<string, string>())
		);

		return acc;
	}, new Map() as RemoteOrderIds);

	const ingests: draft_order.DraftOrderIngest[] = [];

	for (const orderRequest of values) {
		// Draft orders are either updates or inserts
		match(orderRequest.status, {
			Draft: () => {
				if (orderRequest.order_id) {
					const items: draft_order.DraftOrderItemIngest[] = orderRequest.items.map(item => {
						const commonItem = {
							// NOTE: VICTOR - This changes the arrival_at to the date from target_deliver_before_timestamp,
							// can default to that since it will always exist and is the final date for delivery?
							arrival_at: orderRequest.target_deliver_before_timestamp,
							context: createPartSelectionContexts(item.context),
							grade: item.grade,
							price: item.price,
							quantity: item.quantity
						};

						if (item.id) {
							remoteIds.get(orderRequest.local_id)?.delete(item.id);
							return <draft_order.DraftOrderItemIngest>{
								Update: { id: item.id, ...commonItem }
							};
						}
						return <draft_order.DraftOrderItemIngest>{
							Insert: {
								...commonItem,
								buyable: item.buyable
							}
						};
					});

					const itemsToDelete = Array.from(remoteIds.get(orderRequest.order_id)?.keys() ?? []);
					for (const toDelete of itemsToDelete) {
						items.push({ Remove: toDelete });
					}

					remoteIds.delete(orderRequest.order_id);

					ingests.push({
						Update: {
							id: orderRequest.order_id,
							attempt_auto_transition_order: instantAccept,
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							estimator_notes: orderRequest.estimator_notes,
							supply_hash_id: supplyHashId,
							images: orderRequest.images.map(image => image.original),
							items
						}
					});
				} else {
					// If the order is new, we don't need to check any of the ids
					ingests.push({
						Insert: {
							attempt_auto_transition_order: instantAccept,
							estimator_notes: orderRequest.estimator_notes,
							supply_hash_id: supplyHashId,
							images: orderRequest.images.map(image => image.original),
							vendor: {
								Partner: orderRequest.vendor.Partner.id
							},
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							items: orderRequest.items.map(item => {
								const insert: draft_order.DraftOrderItemInsert = {
									buyable: item.buyable,
									arrival_at: orderRequest.target_deliver_before_timestamp,
									context: createPartSelectionContexts(item.context),
									grade: item.grade,
									price: item.price,
									quantity: item.quantity
								};

								return insert;
							})
						}
					});
				}
			},
			Cancelled: () => {
				/** no-op. Cancels are done immedietly  */
			},
			Processing: () => {
				/** no-op. No updates for processing */
			},
			Processed: () => {
				const areAllRejected = orderRequest.items.every(item => {
					return match(item.status, {
						Pending: () => false,
						Approved: () => false,
						Rejected: () => true
					});
				});
				// todo: cleanup, also we need to do something to
				// orders that are all rejected (cancelled maybe)?
				if (!areAllRejected && orderRequest.order_id) {
					ingests.push({
						Finalise: {
							id: orderRequest.order_id,
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							items: orderRequest.items
								.map(item =>
									match<
										draft_order.DraftOrderItemStatus,
										draft_order.DraftOrderItemFinalise | null
									>(item.status, {
										Pending: () => null,
										Approved: ({ reason, details }) => {
											const finalise: draft_order.DraftOrderItemFinalise = {
												id: item.local_id,
												order_separately: item.order_separately,
												quantity: item.quantity,
												status: undefined,
												status_detail: undefined
											};

											match(reason, {
												Estimator: () => {
													finalise.status = 'Approved';
													finalise.status_detail = details;
												},
												Supplier: () => {
													/** no-op, remote is already correct */
												}
											});

											return finalise;
										},
										Rejected: ({ details, reason }) => {
											const finalise: draft_order.DraftOrderItemFinalise = {
												id: item.local_id,
												order_separately: item.order_separately,
												quantity: item.quantity,
												status: undefined,
												status_detail: undefined
											};

											match(reason, {
												Estimator: reason => {
													finalise.status = {
														Rejected: reason
													};
													finalise.status_detail = details;
												},
												Supplier: () => {
													/** no-op, remote is already correct */
												}
											});

											return finalise;
										}
									})
								)
								.filter(isDefined)
						}
					});
				}
			},
			Finalised: () => {
				/** no-op. End of flow  */
			}
		});
	}

	const ordersToDelete = Array.from(remoteIds.keys());
	for (const toDelete of ordersToDelete) {
		ingests.push({ Remove: toDelete });
	}

	return ingests;
};

export const onRecommendationSelect = (
	offerIds: string[],
	recommendations: GetJobSupplyRecommendationsResult,
	draftOrders: Record<string, OrderRequestModel>
) => {
	const selection = cloneDeep(draftOrders);

	for (const draftOrder of Object.values(selection)) {
		if (draftOrder.status !== 'Draft') {
			continue;
		}

		draftOrder.items = draftOrder.items.filter(item => item.buyable.type !== 'Listing');
	}

	for (const offerId of offerIds) {
		const offer = recommendations.offers[offerId];
		if (!offer) {
			continue;
		}

		// It's possible in the future to have two items that fulfill the same
		// part (kit), if the recommendation engine returns two offers that can
		// fulfill the same part. For now, keep it simple and don't auto select a
		// kit - let the user do it themselves.
		// https://www.notion.so/partly/Repair-app-recommendations-should-support-kits-1222cf966862806186cceb00bc131576
		if (offer.gapcParts.length !== 1) {
			continue;
		}

		// Step 2: Check if we already have a draft order for this vendor
		// that we can re-use.
		const existingRequest = Object.values(selection).find(
			order => order.vendor.Partner.id === offer.vendor.id && order.status === 'Draft'
		);

		// Step 3: Find the job part that this offer is for, we need this for
		// the context + default quantity.
		const jobPart = recommendations.parts.find(
			part => part.part.partIdentity === offer.gapcParts[0].partIdentity
		);

		// Step 4: Create a new item for the draft order
		const context = createItemContext(jobPart?.part);
		const newItem = createOrderRequestItem(offer, context, jobPart?.part.quantity ?? 1);

		// Step 5: If we don't have an existing request, create a new one
		// with a single item.
		if (!existingRequest) {
			const newOrder = createNewOrderRequest(offer.vendor, [newItem]);
			selection[newOrder.local_id] = newOrder;
			continue;
		}

		existingRequest.items.push(newItem);
	}

	for (const draftOrder of Object.values(selection)) {
		if (draftOrder.items.length === 0) {
			delete selection[draftOrder.local_id];
		}
	}

	return selection;
};

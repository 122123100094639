import { UvdbAggregateProperties, UvdbBase, UvdbProperties, UvdbProperty } from '../types';
import { Context } from '../utils/context';
import { getId } from '../utils/parsing';
import { getUvdbSuidType, isMultipleUvdbPropertyKey } from '@partly/frontend-utils';

export const createUvdbAggregateProperties = (
	ids: string[],
	ctx: Context
): UvdbAggregateProperties =>
	createUvdbPropertiesList(ids, ctx).reduce((acc, property) => {
		if (!property) {
			return acc;
		}

		return {
			...acc,
			[property.type]: property
		};
	}, {});

export const createUvdbBase = (id: string | null | undefined, ctx: Context): UvdbBase | null => {
	if (!id) {
		return null;
	}

	const uvdbBase = ctx.resources.get({ path: 'uvdb_properties', id });
	if (!uvdbBase) {
		return null;
	}

	return {
		id: uvdbBase.id,
		name: uvdbBase.name
	};
};

export const createUvdbProperty = (
	id: string | null | undefined,
	ctx: Context
): UvdbProperty | null => {
	const uvdbBase = createUvdbBase(id, ctx);

	if (!uvdbBase) {
		return null;
	}

	const type = getUvdbSuidType(uvdbBase.id);
	if (!type) {
		return null;
	}

	return {
		...uvdbBase,
		type
	};
};

export const createUvdbPropertiesList = (items: string[], ctx: Context): UvdbProperty[] =>
	items
		.map(item => {
			const id = getId(item);
			return createUvdbProperty(id, ctx);
		})
		.filter((property): property is UvdbProperty => !!property);

export const createUvdbProperties = (items: string[], ctx: Context) =>
	createUvdbPropertiesList(items, ctx).reduce((acc: UvdbProperties, property) => {
		if (isMultipleUvdbPropertyKey(property.type)) {
			const value = acc[property.type] ?? [];
			value.push(property);
			acc[property.type] = value;
			return acc;
		}

		acc[property.type] = property;
		return acc;
	}, {} as UvdbProperties);

import { DefaultText, GradeCell, HeaderCell } from '@/app/common/components/molecules/cell-builder';
import { formatPrice } from '@/app/utils/currency';
import { isDefined } from '@partly/js-ex';
import { listing, order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { Checkbox } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { OrderItemStatus } from './components/order-item-status-badge';
import { ReturnDecisionBadge } from './components/return-decision-badge';

export type OrderTableItem = {
	id: string;
	quantity: number;
	status: order.OrderItemStatus;
	grade: listing.ListingGrade | null;
	price: number | null;
	return_policy: order.OrderItemReturnPolicy;
	name: string;
};

export const orderColumnBuilder = () => {
	const columnHelper = createColumnHelper<OrderTableItem>();

	const select = (hasHeader = true) =>
		columnHelper.display({
			id: 'select',
			header: hasHeader
				? ({ table }) => (
						<Checkbox
							aria-label="Check all rows"
							className="inline-block"
							size="xs"
							{...{
								checked: table.getIsAllRowsSelected(),
								indeterminate: table.getIsSomeRowsSelected(),
								onChange: table.getToggleAllRowsSelectedHandler()
							}}
						/>
					)
				: undefined,
			cell: ({ row }) => (
				<Checkbox
					aria-label="Check row"
					className="inline-block"
					size="xs"
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler()
					}}
				/>
			)
		});

	const quantity = () =>
		columnHelper.accessor('quantity', {
			id: 'quantity',
			header: () => <HeaderCell>Qty.</HeaderCell>,
			cell: info => {
				const { status } = info.row.original;
				if (typeof status === 'object' && 'returned' in status) {
					const newQuantity = info.getValue() - status.returned.quantity;
					return (
						<DefaultText className="space-x-2">
							<span className="line-through">{info.getValue()}</span>
							<span>{newQuantity}</span>
						</DefaultText>
					);
				}

				return <DefaultText>{info.getValue()}</DefaultText>;
			}
		});

	const name = () =>
		columnHelper.accessor('name', {
			id: 'name',
			header: () => <HeaderCell className="min-w-[200px]">Items</HeaderCell>,
			cell: info => <DefaultText>{info.getValue()}</DefaultText>
		});

	const grade = () =>
		columnHelper.accessor('grade', {
			id: 'grade',
			header: () => <HeaderCell>Grade</HeaderCell>,
			cell: info => <GradeCell value={info.getValue()} />
		});

	const status = () =>
		columnHelper.accessor('status', {
			id: 'status',
			header: () => <HeaderCell>Status</HeaderCell>,
			cell: info => {
				const status = info.getValue();
				if (!status) {
					return <DefaultText>--</DefaultText>;
				}

				const reason = match<order.OrderItemStatus, string | null>(status, {
					arrived: ({ details }) => details,
					returned: ({ details }) => details,
					pending: () => null
				});

				return (
					<>
						<OrderItemStatus status={status} size="small" />
						{reason && <div className="text-xs text-gray-900 mt-1">{reason}</div>}
					</>
				);
			}
		});

	const returnDecision = () =>
		columnHelper.display({
			id: 'return_decision',
			header: () => <HeaderCell>Supplier decision</HeaderCell>,
			cell: info => {
				return match(info.row.original.status, {
					pending: () => <DefaultText>--</DefaultText>,
					arrived: () => <DefaultText>--</DefaultText>,
					returned: arg => {
						let reason = null;
						if (arg.decision) {
							reason = match<order.OrderItemReturnDecision, string | null>(arg.decision, {
								accepted: ({ notes }) => notes,
								rejected: ({ notes }) => notes,
								undecided: () => null
							});
						}

						return (
							<>
								<ReturnDecisionBadge status={info.row.original.status} size="small" />
								{reason && <div className="text-xs text-gray-900 mt-1">{reason}</div>}
							</>
						);
					}
				});
			}
		});

	const returnPolicy = () =>
		columnHelper.accessor('return_policy', {
			id: 'return_policy',
			header: () => <HeaderCell>Returns</HeaderCell>,
			cell: info => {
				const returnPolicy = info.getValue();
				return match(returnPolicy, {
					allow_returns: ({ before_date }) => {
						if (before_date) {
							return <DefaultText>Before {formatDate(before_date, 'dd/MM')}</DefaultText>;
						}

						return <DefaultText>Yes</DefaultText>;
					},
					no_returns: () => {
						return <DefaultText>No</DefaultText>;
					}
				});
			}
		});

	const price = () =>
		columnHelper.accessor('price', {
			id: 'price',
			header: () => <HeaderCell className="text-right">Price</HeaderCell>,
			cell: info => {
				const price = info.getValue();
				if (!isDefined(price) || price === 0) {
					return <DefaultText className="text-right">--</DefaultText>;
				}

				return <DefaultText className="text-right">{formatPrice(price)}</DefaultText>;
			}
		});

	return {
		select,
		quantity,
		name,
		status,
		returnDecision,
		returnPolicy,
		grade,
		price
	};
};

import { queries } from '@/sdk/react/queries';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { match } from '@/types/match';
import { draft_order, order } from '@/sdk/reflect/reflect';
import { CombinedOrderItem, CombintedOrderStatusInfo } from '../../models';

export const useCombinedOrdersSummary = (jobId: string) => {
	const [{ data: ordersData }, { data: orderRequestsData }] = useSuspenseQueries({
		queries: [
			// We are refetching every 30 seconds in case the data changes, maybe need
			// realtime here, but seen as we are pushing for more async only notified if
			// something is wrong then can likely get away with it.
			{ ...queries.orders.list({ job_id: jobId }), refetchInterval: 30_000 },
			{ ...queries.draft_orders.list({ job_id: jobId }), refetchInterval: 30_000 }
		]
	});

	const items = useMemo<CombinedOrderItem[]>(() => {
		const newItems: Map<order.OrderId, CombinedOrderItem> = new Map();

		for (const order of ordersData.orders) {
			const status = match<order.OrderStatus, CombintedOrderStatusInfo>(
				order.status,
				{
					cancelled: () => ({ status: 'Cancelled' }),
					acknowledged: () => ({ status: 'Confirmed' }),
					ordered: () => ({
						status: 'Ordered',
						details: "Supplier hasn't confirmed this order yet."
					})
				},
				() => ({ status: 'Ordered' })
			);

			newItems.set(order.id, {
				name: `${order.vendor.partner.name} #(${order.id})`,
				statusInfo: status,
				deliveryInfo: { date: order.deliver_before_timestamp },
				totalPrice: order.items.reduce((acc, item) => acc + item.price * item.quantity, 0),
				href: `/job/${jobId}/orders` // (todo: maybe highlight?)
			});
		}

		for (const draftOrder of orderRequestsData.draft_orders) {
			if (newItems.has(draftOrder.id)) {
				// A real order is always more important
				// than a draft order.
				continue;
			}

			const status = match<draft_order.DraftOrderStatus, CombintedOrderStatusInfo>(
				draftOrder.status,
				{
					Cancelled: () => ({ status: 'Cancelled' }),
					Draft: () => ({ status: 'Draft' }),
					Processing: () => {
						if (draftOrder.attempt_auto_transition_order.enabled) {
							return { status: 'Ordered', details: 'Order will be confirmed by supplier soon.' };
						}

						return { status: 'Processing' };
					},
					// this should be no-op, because finalised means that there
					// is a real order.
					Finalised: () => ({ status: 'Ordered' }),
					Processed: () => ({ status: 'NeedsAttention' })
				},
				() => ({ status: 'Draft' })
			);

			newItems.set(draftOrder.id, {
				name: `${draftOrder.vendor.Partner.name} #(${draftOrder.id})`,
				statusInfo: status,
				deliveryInfo: {
					date: draftOrder.target_deliver_before_timestamp,
					details: 'Estimated delivery date'
				},
				totalPrice: draftOrder.items.reduce(
					(acc, item) => acc + (item.price ?? 0) * item.quantity,
					0
				),
				href: `/job/${jobId}/supply?order=${draftOrder.id}`
			});
		}

		return Array.from(newItems.values()).sort((a, b) => a.deliveryInfo.date - b.deliveryInfo.date);
	}, [ordersData, orderRequestsData]);

	return { items };
};

import { VehicleSelectorForm } from '@/app/features/vehicle/components/vehicle-selector-form';
import {
	OemOption,
	VehicleSelectorFormData,
	VehicleSelectorFormSelection
} from '@/app/features/vehicle/components/vehicle-selector-form/types';
import {
	AggregatedVehicleOptions,
	compareOemCb,
	EXCLUDED_OEM_PROPS,
	EXCLUDED_UVDB_PROPS,
	extractOemProps,
	extractUvdbProps,
	UVDB_BODY_NUM_DOORS,
	UVDB_BODY_TYPE,
	uvdbPropertiesToArray
} from '@/app/features/vehicle/hooks/use-vehicle-search';
import { useUnsavedChanges } from '@/app/hooks/use-unsaved-changes';
import { getSupportedBodyNumDoors, getSupportedBodyTypes } from '@/app/utils/collision-map';
import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';
import { VehicleConfiguration } from '@sdk/lib';
import { createVehicleName } from '@utils/vehicle';
import {
	castArray,
	groupBy,
	isNil,
	isString,
	keys,
	map,
	mapValues,
	omit,
	omitBy,
	sortBy
} from 'lodash-es';
import { FormEvent, useMemo, useState } from 'react';
import { UvdbPropertyKey } from '@partly/frontend-utils';
import { VehicleCard } from '../vehicle-card';

export type VehicleResultsFormData = {
	vehicleId: string | null;
	label: string;
	uvdbProperties: string[];
};

type VehicleResultsFormProps = Omit<
	InheritableElementProps<
		'form',
		{
			siteId: string;
			vehicles: VehicleConfiguration[];
			chassis?: string;
			aggregates: {
				selectOptions: VehicleSelectorFormData;
				differentOptions: AggregatedVehicleOptions;
			};
			onSubmit: (data: VehicleResultsFormData) => void;
		}
	>,
	'noValidate'
>;

const VehicleResultsForm = ({
	siteId,
	vehicles,
	chassis,
	onSubmit,
	aggregates
}: VehicleResultsFormProps) => {
	const { differentOptions, selectOptions } = aggregates;
	const [selectedOptions, setSelectedOptions] = useState<VehicleSelectorFormSelection>({});

	// We must ask for BodyType and BodyNumDoors if they are ambiguous or not in vehicle configurations
	const requiredSelectOptions = useMemo<VehicleSelectorFormData>(() => {
		const options: VehicleSelectorFormData = {};

		const selectedBodyType = selectedOptions[UVDB_BODY_TYPE]?.id;
		const selectedBodyNumDoors = selectedOptions[UVDB_BODY_NUM_DOORS]?.id;

		// Show body type selection and further trim down selection on selected body num doors
		// each vehicle has only one body type
		const supportedBodyTypes = getSupportedBodyTypes(
			selectedBodyNumDoors ? [selectedBodyNumDoors] : []
		);

		options[UVDB_BODY_TYPE] = selectOptions[UVDB_BODY_TYPE]?.filter(({ id }: { id: string }) =>
			supportedBodyTypes.includes(id)
		);

		// Show body num doors selection and further trim down selection on selected body types
		const supportedBodyNumDoors = getSupportedBodyNumDoors(
			selectedBodyType ? [selectedBodyType] : []
		);

		options[UVDB_BODY_NUM_DOORS] = selectOptions[UVDB_BODY_NUM_DOORS]?.filter(
			({ id }: { id: string }) => supportedBodyNumDoors.includes(id)
		);

		return options;
	}, [vehicles, selectOptions, selectedOptions]);

	// options with only one item
	const preselectedOptions = useMemo(() => {
		return mapValues(
			omitBy(requiredSelectOptions, options => isNil(options) || options.length > 1),
			values => values?.[0] ?? null
		);
	}, [requiredSelectOptions]);

	const allSelectedOptions = useMemo(
		() => ({ ...selectedOptions, ...preselectedOptions }),
		[preselectedOptions, selectOptions]
	);

	const showSelector = useMemo(() => {
		const requiredSize = Object.keys(requiredSelectOptions).length;
		const preselectedSize = Object.keys(requiredSelectOptions).length;

		return !!(
			differentOptions.oemProperties.length ||
			differentOptions.uvdbProperties.length ||
			(requiredSize && requiredSize !== preselectedSize)
		);
	}, [vehicles, differentOptions, requiredSelectOptions]);

	const selectedVehicles = useMemo(() => {
		const requiredKeys = Object.keys(requiredSelectOptions);

		return vehicles.filter((vehicle: VehicleConfiguration) => {
			for (const option of Object.values(allSelectedOptions)) {
				if (!option) {
					continue;
				}

				const uvdbProp = vehicle.uvdbProperties[option.type as UvdbPropertyKey];

				// bodyType and bodyNumDoors (requiredKeys) could not be present in a configuration
				if (requiredKeys.includes(option.type) && !uvdbProp) {
					continue;
				}

				const vehicleProperty = uvdbProp
					? castArray(uvdbProp)
					: vehicle.properties.filter(v => v.type === option.type);

				const isMatch = vehicleProperty.some(
					v => ('id' in v && v.id === option.id) || ('value' in v && String(v.value) === option.id)
				);

				if (!isMatch) {
					return false;
				}
			}

			return true;
		});
	}, [vehicles, requiredSelectOptions, allSelectedOptions]);

	const selectorFormData = useMemo<VehicleSelectorFormData>(() => {
		// filter out options from excluded vehicles

		const oemProps = map(extractOemProps(selectedVehicles), compareOemCb);
		const oemOptions = mapValues(groupBy(differentOptions.oemProperties, 'type'), props =>
			props
				.filter(p => oemProps.includes(compareOemCb(p)))
				.map(
					({ type, value }): OemOption => ({
						id: String(value),
						name: String(value),
						type
					})
				)
		);

		const uvdbPropsIds = map(extractUvdbProps(selectedVehicles), 'id');
		const uvdbOptions = mapValues(groupBy(differentOptions.uvdbProperties, 'type'), props =>
			props.filter(p => uvdbPropsIds.includes(p.id))
		);

		// skip preselected options
		const requiredOptions = omitBy(
			requiredSelectOptions,
			(_, key) => !isNil(preselectedOptions[key])
		);

		return {
			...requiredOptions,
			...uvdbOptions,
			...oemOptions
		};
	}, [requiredSelectOptions, preselectedOptions, selectedVehicles]);

	const validation = useMemo(() => {
		const requiredOptionsPicked = Object.keys(requiredSelectOptions).every(
			key => allSelectedOptions[key]
		);

		const valid = requiredOptionsPicked && selectedVehicles.length === 1;

		return {
			required: !valid
		};
	}, [selectedVehicles, allSelectedOptions]);

	const onJobSubmit = (e: FormEvent) => {
		e.preventDefault();

		if (!selectedVehicles.length) {
			return;
		}

		const vehicleProperties: string[] = map(
			uvdbPropertiesToArray(selectedVehicles[0].uvdbProperties),
			'id'
		);
		const requiredProperties = Object.keys(requiredSelectOptions)
			.map(key => allSelectedOptions[key]?.id)
			.filter(isString);

		onSubmit({
			vehicleId: selectedVehicles[0].token,
			label: createVehicleName(selectedVehicles[0]),
			uvdbProperties: [...vehicleProperties, ...requiredProperties]
		});
	};

	// pick the vehicle with the least info until disambiguated
	const selectedVehicle = useMemo(() => {
		if (selectedVehicles.length === 1) {
			return selectedVehicles[0];
		}
		const { token, uvdbProperties, properties } = sortBy(
			selectedVehicles,
			({ properties, uvdbProperties }) => properties.length + keys(uvdbProperties).length
		)[0];

		return {
			token,
			properties: properties.filter(
				prop =>
					differentOptions.oemProperties.every(({ type }) => prop.type !== type) ||
					!EXCLUDED_OEM_PROPS.includes(prop.type)
			),
			uvdbProperties: omit(uvdbProperties, [
				...differentOptions.uvdbProperties.map(({ type }) => type),
				...EXCLUDED_UVDB_PROPS
			])
		};
	}, [selectedVehicles, differentOptions]);

	useUnsavedChanges(({ nextLocation }) =>
		[`/jobs?siteId=${siteId}`, `/jobs/create?siteId=${siteId}`].includes(nextLocation.pathname)
	);

	return (
		<>
			<div className="flex items-center justify-between mb-1 text-sm">
				<span className="font-medium">Vehicle</span>
				{chassis && <span className="text-sm">{chassis}</span>}
			</div>
			<VehicleCard vehicle={selectedVehicle} />
			{showSelector && <dt className="my-3 text-sm font-medium">Complete your vehicle</dt>}
			<VehicleSelectorForm
				data={selectorFormData}
				onChange={(v: VehicleSelectorFormSelection) => setSelectedOptions(v)}
				onSubmit={onJobSubmit}
			>
				{({ isValid }) => (
					<div className="space-y-6">
						<div className="space-y-3">
							{map(selectorFormData, (_: any, key: UvdbPropertyKey) => (
								<VehicleSelectorForm.Input
									key={key}
									field={key}
									labelProps={{ className: 'font-normal mb-1' }}
									rules={validation}
								/>
							))}
						</div>

						<Button disabled={!isValid} className="w-full" type="submit">
							{Object.keys(selectorFormData).length > 0 ? 'Create Job' : 'Confirm vehicle'}
						</Button>
					</div>
				)}
			</VehicleSelectorForm>
		</>
	);
};

export default VehicleResultsForm;

import { useAuth } from '@/app/hooks/use-auth';
import { Job } from '@/sdk/lib';
import { jobsQueries, userQueries } from '@/sdk/react';
import { BusinessEntityContext, EventParams, JobContext, UserContext } from '@partly/analytics';
import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import { mixpanel } from '@utils/analytics/mixpanel';
import { isNil } from 'lodash-es';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { matchPath } from 'react-router-dom';

declare global {
	interface Window {
		mixpanel?: ReturnType<typeof mixpanel>;
	}
}

type AnalyticsContext = {
	mixpanel: ReturnType<typeof mixpanel>;
	businessContext: Partial<UserContext & BusinessEntityContext>;
	jobContext: JobContext | null;
};

const AnalyticsContext = createContext({} as AnalyticsContext);

export const AnalyticsProvider = ({
	children,
	token,
	apiHost
}: {
	children: ReactNode;
	token?: string;
	apiHost?: string;
}) => {
	const [instance, setInstance] = useState(window.mixpanel);
	const businessContext = useActiveBusinessContext();
	const jobContext = useActiveJobContext();

	useEffect(() => {
		if (!token) {
			return;
		}

		const mixpanelInstance = mixpanel(window, document);
		if (mixpanelInstance) {
			mixpanelInstance.init(token, { debug: false, api_host: apiHost });
		}
		setInstance(mixpanelInstance);
	}, [token]);

	// Mixpanel context
	useEffect(() => {
		if (isNil(instance)) {
			return;
		}

		if (!isNil(businessContext.user_id)) {
			const mixpanelId = instance.getProperty('$user_id');
			// No need to ID a user that's already ID'd
			if (!isNil(mixpanelId)) {
				return;
			}

			const identity = {
				user_id: businessContext.user_id,
				name: businessContext.user_name,
				$email: businessContext.user_email
			};

			instance.identify(businessContext.user_id);
			instance.register(identity);
			instance.people.set(identity);
		} else {
			instance.reset();
		}
	}, [instance, businessContext.user_id, businessContext.user_email]);

	// Sentry context
	useEffect(() => {
		if (!isNil(businessContext.user_id)) {
			// Set the Sentry user context
			Sentry.setUser({
				id: businessContext.user_id,
				email: businessContext.user_email,
				username: businessContext.user_name
			});

			// Disable Sentry session recording for Partly special accounts
			const emailsToNotRecord = ['robouser@partly.com'];
			if (
				businessContext.user_email?.endsWith('@partly.com') &&
				emailsToNotRecord.includes(businessContext.user_email)
			) {
				const replay = Sentry.getReplay();
				replay?.stop().catch(() => {
					console.log('Error disabling replay');
				});
			}
		} else {
			Sentry.setUser(null);
		}
	}, [businessContext.user_id, businessContext.user_email]);

	return (
		<AnalyticsContext.Provider value={{ mixpanel: instance, businessContext, jobContext }}>
			{children}
		</AnalyticsContext.Provider>
	);
};

const useActiveBusinessContext = () => {
	const [context, setContext] = useState<Partial<UserContext & BusinessEntityContext>>({
		application: 'REPAIR_APP'
	});
	const { data, isLoading } = useQuery(userQueries.get());
	const { isAuthenticated } = useAuth();

	const activeSiteId = useMemo(
		() => new URLSearchParams(window.location.search).get('siteId'),
		[window.location.search]
	);

	useEffect(() => {
		if (isLoading) {
			return;
		}

		// logged in (not logged out yet, has user data, and user is not guest)
		if (isAuthenticated && !isNil(data?.user) && 'account' in data.user) {
			const { account } = data.user;

			// Auto-fill context
			const user: UserContext = {
				user_id: account.id,
				user_email: account.email,
				user_name: `${account.firstName} ${account.lastName}`
			};

			// TODO:- ideally we'd use the repair-org-id if there is no specific repair-site selected
			const entityId = activeSiteId ?? data.business_entities.at(0)?.id;
			const entityName = data.business_entities.find(entity => entity.id === entityId)?.name;

			const business: Partial<BusinessEntityContext> = {
				business_entity_id: entityId,
				business_entity_name: entityName,
				business_entity_type: 'REPAIRER',
				application: 'REPAIR_APP'
			};

			setContext({ ...user, ...business });
		} else {
			setContext({
				application: 'REPAIR_APP'
			});
		}
	}, [isAuthenticated, isLoading, data?.user, data?.business_entities, activeSiteId]);

	return context;
};

/**
 * Most application actions happen within the context of a specific job.
 * This hook provides this current job context.
 */
const useActiveJobContext = () => {
	// Can be in search or in path depending on the page
	const jobId = useMemo(() => {
		const match = matchPath(
			{ path: '/job/:JobId', end: false, caseSensitive: false },
			window.location.pathname
		);

		if (match?.params.JobId?.length) {
			return match?.params.JobId;
		}

		const search = new URLSearchParams(window.location.search).get('jobId');

		if (search?.length) {
			return search;
		}
	}, [window.location.pathname, window.location.search]);

	const { data: jobData } = useQuery({
		...jobsQueries.get({ jobId: jobId || '' }),
		enabled: !!jobId
	});

	// Computed job context
	const job = useMemo(() => {
		if (jobData) {
			const context = jobContextFromJob(jobData.job);
			return context;
		}
		if (jobId) {
			return { job_id: jobId };
		}
		return null;
	}, [jobData, jobId]);

	return job;
};

export function jobContextFromJob(job: Job): JobContext {
	const context = {
		job_id: job.id,
		job_number: job.jobNumber ?? undefined,
		claim_number: job.claimNumber ?? undefined,
		invoice_payer_id: undefined, // TODO - not in API
		invoice_payer_name: 'IAG NZ', // TODO - not in API
		oem_vehicle_id: job.vehicle?.variant?.token ?? undefined,
		vehicle_chassis: job.vehicle?.chassisNumber ?? undefined,
		vehicle_registration: job.vehicle?.plateNumber ?? undefined
	};

	return context;
}

export const useAnalytics = () => {
	const { mixpanel, businessContext, jobContext } = useContext(AnalyticsContext);

	const logEvent = ({ params, e }: EventParams) => {
		if (isNil(params) || isNil(e)) {
			return;
		}

		mixpanel?.track(e, {
			...businessContext,
			...(jobContext ?? {}),
			...params,
			host: window.location?.host
		});
		// Send event to Sentry as a breadcrumb, so if this transaction is sampled we can see the event
		Sentry.addBreadcrumb({
			message: e,
			data: params,
			level: 'info'
		});
	};

	return {
		logEvent
	};
};

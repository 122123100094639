import JobStatusBadge from '@/app/features/jobs/components/job-status-badge';
import { Section } from '@/app/templates/page-section';
import { tlsx } from '@/app/utils/tw-merge';
import { Job } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';

export const JobDetails = ({
	job,
	className
}: InheritableElementProps<'section', { job: Job }>) => {
	return (
		<Section className={tlsx('bg-white p-6 border rounded-md', className)}>
			<div className="flex items-center justify-between w-full">
				<Section.Title className="text-lg">Job #{job.jobNumber}</Section.Title>
				<JobStatusBadge status={job.status} />
			</div>
			<dl className="mt-4 text-sm text-gray-900 space-y-2">
				{job.claimNumber && (
					<div className="flex items-center justify-between">
						<dt className="font-medium">Claim number</dt>
						<dd>{job.claimNumber}</dd>
					</div>
				)}
				{job.vehicle?.variant && (
					<div className="flex items-center justify-between">
						<dt className="font-medium">Vehicle name</dt>
						<dd>{job.vehicle?.variant?.description}</dd>
					</div>
				)}
				{job.vehicle?.plateNumber && (
					<div className="flex items-center justify-between">
						<dt className="font-medium">Plate number</dt>
						<dd>{job.vehicle?.plateNumber?.toUpperCase()}</dd>
					</div>
				)}
				{job.vehicle?.chassisNumber && (
					<div className="flex items-center justify-between">
						<dt className="font-medium">Chassis number</dt>
						<dd>{job.vehicle?.chassisNumber}</dd>
					</div>
				)}
				<div className="flex items-center justify-between">
					<dt className="font-medium">Created at</dt>
					<dd>{new Date(job.createdAt).toLocaleDateString('en-NZ')}</dd>
				</div>
			</dl>
		</Section>
	);
};
